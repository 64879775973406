<template>
    <div class="flex center env">
        <div class="col8 flex wrap">
            <div class="col5 hvr-float" v-for="item in data" :key="item.title">
              <div class="flex center" style="    max-height: 360px;
               
    height: 90%;
    padding: 20px 0px;
    min-height: 250px;">
                    <img style="    width: 100%;
    max-width: 540px;
    height: inherit;
    max-height: inherit;
    min-height: inherit;" v-lazy="require('../../assets/about/env/'+item.url)">
                </div>
            </div>
            <!-- <div :class="'hvr-float col6-4 flex center wrap '+(index!=(data.length-1)?'item':'')" v-for="(item,index) in data" :key="item.title">
                <div class="col6" :style="'text-align:left;'+((item.order&&width>768)?'order:1':'')">
                    <div class="col9" style="font-size:0.9rem;margin:0 auto">
                        <div class="space_content" style="font-size:1rem;margin-bottom: 20px;font-weight:bold;margin-top: 10px;">
                            {{item.title}}
                        </div>
                        <div class="space_content" style="margin-bottom: 20px;">
                        {{item.content[0]}}<br/>{{item.content[1]}}
                        </div>
                        <div class="space_content" style="text-indent:0rem;">
                                {{item.link[0]}}<br/>
                                {{item.link[1]}}<br/>
                                {{item.link[2]}}<br/>
                        </div>
                    </div>
                </div>
                <div class="col4 flex center" style="    max-height: 360px;
               
    height: 90%;
    padding: 20px 0px;
    min-height: 250px;">
                    <img style="    width: 100%;
    max-width: 540px;
    height: inherit;
    max-height: inherit;
    min-height: inherit;" v-lazy="require('../../assets/about/env/'+item.url)">
                </div>
            </div> -->

        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            data:[
                {url:'1.jpg',title:'法医物证（DNA）实验室',content:['历思实验室拥有经验丰富、技术精湛的专家鉴定人与助理人员。选用美国先进设备，可检验血斑、精斑、混合斑、口腔拭子、毛发（带毛囊）、口香糖、烟蒂、羊水、人体组织、脱落细胞、病理蜡块、病理切片等，能满足亲子鉴定、亲缘鉴定、个人识别等绝大多数案件的鉴定需求。','欢迎公、检、法、司及各界有需求的用户咨询并送检。'],link:['联系方式','张老师','电话：0592-2283132']},
                {url:'2.jpg',title:'法医毒物实验室',content:['福建历思司法鉴定所毒物室由多名专家及执业鉴定人组成，拥有业内先进检测仪器气相色谱仪、气相色谱-质谱仪等，业务范围包括：乙醇检验、医用合成药物检验、毒品检验，可为执法机关提供专业、快速、高效的检验检测工作。','欢迎公检法司及各界相关用户咨询并送检。'],link:['联系方式','张老师','电话：0592-2283132'],order:true},
                {url:'3.jpg',title:'法医临床实验室',content:['福建历思司法鉴定所法医临床室拥有数十位既有临床工作经验又具有司法鉴定经验的鉴定专家，能够解决多学科、多专业领域的疑难复杂医疗损害案件；同时拥有有丰富教学经验、鉴定经验的法医学教授，能够解决疑难复杂的伤残、伤病关系、损伤程度鉴定。','欢迎公检法司及各界相关用户咨询并送检。'],link:['联系方式','张老师','电话：0592-2283132'],},
                {url:'4.jpg',title:'微量物证实验室',content:['福建历思司法鉴定所微量物证实验室由多名业内知名专家和经验丰富鉴定助理组成，实验室拥有质谱仪、色谱仪等多台高精尖检测设备，可对多种物质进行微量检测鉴定。其中包括：油漆、塑料类物质。','欢迎公检法司及各界相关用户咨询并送检。'],link:['联系方式','张老师','电话：0592-2283132'],order:true},
            ]
        }
    },
    computed:{
        width(){
            return this.$store.state.width;
        }
    }
}
</script>
<style scoped>
.strength .titlee{
    text-align:left;
    margin-left:2rem;
    color:#17E1D7;
}
.strength .content{
    text-indent:1.6rem;
    font-size: 0.8rem;
    text-align:left;
    color:#fff;
}
.env .item{
    border-bottom: 1px solid #dddddd;
}
.env{
    margin-bottom:2rem;
}
</style>